<template>
    <div>
      <CButtonGroup vertical class="w-100 d-xs-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
        <slot></slot>
      </CButtonGroup>
      <CButtonGroup class="float-right d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex">
        <slot></slot>
      </CButtonGroup>
    </div>
  </template>
  
  <script>
  export default {}
  </script>