<template>
  <CRow>
    <div sm="12" class="w-100">
      <CCol>
        <CCard>
          <CCardBody>
            <CButton color="success" size="sm" class="mb-2 mr-2" @click="clickZipFile">
              <CSpinner v-if="isProcessingFile" size="sm" />
              {{ $t('cert.UploadZIPFile') }}
            </CButton>
            <input type="file" style="display: none" id="zipfile" accept="application/zip, application/octet-stream"
              @change="uploadZipCertificate" />
            <p style="color: #a0a0a0">{{ $t('cert.InstructionZIPFile') }} <a href="/example_format.zip" class="mb-2">{{
              $t('cert.ExampleFile') }}</a>.</p>
          </CCardBody>
        </CCard>
      </CCol>
    </div>

    <CCol sm="12">
      <CCard>
        <CRow :class="enableSelect ? 'p-3' : ''">
          <CCol sm="12" :md="6">
            <p v-if="enableSelect">{{ $t('cert.SelectedCertificates') }}: {{ selectedCertificatesCount }}</p>
          </CCol>
          <CCol sm="12" :md="6" class="text-right">
            <BatchDownloadGroup>

              <CButton color="success" @click="endSelect" v-if="enableSelect">{{ $t('Cancel') }}</CButton>
              <CButton variant='outline' color="primary" @click="selectAll" v-if="enableSelect">{{ $t('SelectAll') }}
              </CButton>
              <CButton variant='outline' color="primary" @click="downloadAll" v-if="enableSelect && total_rows > 0">{{
                $t('DownloadAll') }}</CButton>

            </BatchDownloadGroup>
          </CCol>
        </CRow>
        <CCardBody>
          <template>
            <div class="filters-container">
              <!-- Search Input field -->
              <div class="filter-group">
                <label class="filter-label">{{ $t('Search') }}</label>
                <div class="input-group">
                  <CInput class="rounded-start" v-model="search" @input="searchData"
                    :placeholder="$t('cert.SearchPlaceholder')" />
                </div>
              </div>

              <div class="d-flex">
                <!-- Status Select -->
                <div class="filter-group">
                  <label class="filter-label">{{ $t('cert.FilterByStatus') }}</label>
                  <CSelect v-model="selectedStatus" :options="statusOptions" @change="statusChanged">
                    <template #first>
                      <option disabled value="">{{ $t('cert.SelectStatus') }}</option>
                    </template>
                  </CSelect>
                </div>

                <!-- Datepicker for Expiry Date -->
                <div class="filter-group datepicker-filter">
                  <label class="filter-label">{{ $t('cert.FilterByExpiry') }}</label>
                  <div class="d-flex">
                    <!-- Start Date Picker -->
                    <v-date-picker v-model="startDate" :popover="{ visibility: 'focus' }">
                      <template v-slot="{ inputValue, inputEvents }">
                        <CInput :value="inputValue" :placeholder="$t('cert.StartDate')" v-on="inputEvents"></CInput>
                      </template>
                    </v-date-picker>

                    <!-- Separator -->
                    <div class="px-2 py-2"> - </div>

                    <!-- End Date Picker -->
                    <v-date-picker v-model="endDate" :min="startDate" :popover="{ visibility: 'focus' }">
                      <template v-slot="{ inputValue, inputEvents }">
                        <CInput :value="inputValue" :placeholder="$t('cert.EndDate')" v-on="inputEvents"></CInput>
                      </template>
                    </v-date-picker>
                  </div>
                </div>

                <!-- Items Per Page dropdown -->
                <div class="filter-group">
                  <label class="filter-label">{{ $t('NumberOfItemsPerPage') }}</label>
                  <CSelect v-model="perpage" :options="[10, 20, 50, 100]" @change="paginationChanged(perpage)">
                    <template #first>
                      <option disabled value="">{{ $t('NumberOfItemsPerPage') }}</option>
                    </template>
                  </CSelect>
                </div>
              </div>
            </div>
          </template>

          <CDataTable :items="items" :fields="fields" :loading="listLoading" :items-per-page="perpage"
            :tableFilterValue.sync="search" sorter @row-clicked="selectRow">
            <template #actions="{ item }">
              <td>
                <CButton color="primary" @click="startSelect" class="" variant="outline" square
                  style="margin-left: 10px">
                  <CIcon name="cil-check-alt" v-show="item._selected" />
                  <CIcon v-show="!item._selected" />
                </CButton>
              </td>
            </template>
            <template #cert_type_display="{ item }">
              <td>
                <template v-if="item.cert_type == 'HTTP' && item.force_ssl == true">
                  {{ $t('HTTPS') }}
                </template>
                <template v-else>
                  {{ item.cert_type }}
                </template>
              </td>
            </template>
            <template #expired_at="{ item }">
              <td>
                <template v-if="item.expired_at">
                  {{ $moment(item.expired_at).format('MMM D, YYYY') }}
                </template>
                <template v-else>
                  -
                </template>
              </td>
            </template>
            <template #issue_at="{ item }">
              <td>
                <template v-if="item.issue_at">
                  {{ $moment(item.issue_at).format('MMM D, YYYY') }}
                </template>
                <template v-else>
                  -
                </template>
              </td>
            </template>
            <template #issuer="{ item }">
              <td>
                <template v-if="item.issuer">
                  {{ item.issuer.organization }}
                </template>
                <template v-else>
                  -
                </template>
              </td>

            </template>
            <template #action="{ item }">
              <td>
                <CButton color="primary" @click="downloadCert(item.id, item.name)">
                  {{ $t('Download') }}
                </CButton>
              </td>
            </template>
            <template #under-table>
              <CPagination v-if="total_pages > 0" :activePage.sync="page" :pages="total_pages" align="center"
                @update:activePage="pageChanged" />
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import _ from 'lodash'
import axios from '@/plugins/axios'
import { saveAs } from 'file-saver'
import BatchDownloadGroup from './BatchDownloadGroup.vue'

export default {
  name: 'CertTable',
  components: {
    BatchDownloadGroup,
  },
  data() {
    return {
      items: [],
      listLoading: false,
      total_rows: 0,
      total_pages: 0,
      perpage: 20,
      page: 1,
      search: null,
      selectedStatus: this.$t('cert.Issued'),
      statusOptions: [this.$t('cert.Draft'), this.$t('cert.ExpiringSoon'), this.$t('cert.Expired'), this.$t('cert.Issued'), this.$t('cert.PendingValidation'), this.$t('cert.Error')],
      startDate: null,
      endDate: null,
      enableSelect: false,
      isselected: [],
      sortField: null,
      sortOrder: null,
      isProcessingFile: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'actions',
          label: ''
        },
        {
          key: 'cert_type_display',
          label: this.$t('cert.table.Type')
        },
        {
          key: 'name',
          label: this.$t('cert.table.Domains')
        },
        {
          key: 'issue_state_display',
          label: this.$t('cert.table.Status')
        },
        {
          key: 'issue_at',
          label: this.$t('cert.table.IssueDate')
        },
        {
          key: 'expired_at',
          label: this.$t('cert.table.ExpiresDate')
        },
        {
          key: 'renew',
          label: this.$t('cert.table.AutoRenew'),
          sorter: false,
        },
        {
          key: 'issuer',
          label: this.$t('cert.table.SSLIssuer'),
          sorter: false,
        },
        {
          key: 'action',
          label: ''
        }
      ]
    },
    selectedCertificatesCount() {
      return this.isselected.length;
    },
  },
  watch: {
    search() {
      this.searchData(this)
    },
    '$parent.isActive'() {
      this.fetchData()
    },
    startDate() {
      if (this.endDate) this.fetchData()
    },
    endDate() {
      if (this.startDate) this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    clickZipFile() {
      document.getElementById('zipfile').click();
    },
    uploadZipCertificate(event) {
      if (event.target.files.length === 0) {
        return;
      }
      this.isProcessingFile = true;
      const zipfile = event.target.files[0];
      const formData = new FormData();
      formData.append('zipfile', zipfile);
      axios.post('cert/issue/zip_upload/', formData).then(response => {
        console.log(response);
        this.flash(response.data.success, "success", { timeout: 3000 });
        this.fetchData();
      }).catch(errors => {
        console.log(errors);
        errors.forEach((message) => {
          this.flash(message.replace("error.", ""), 'error', { "timeout": 3000 });
        });
      }).finally(() => {
        this.isProcessingFile = false;
        document.getElementById("zipfile").value = "";
      });
    },

    selectRow(item) {
      this.$set(item, '_selected', !item._selected)
      this.$set(item, '_classes', item._selected ? "alert-primary" : "")
      if (item._selected) {
        this.isselected.push(item)
      } else {
        const index = this.isselected.findIndex((selected) => selected.id === item.id)
        if (index > -1) {
          this.isselected.splice(index, 1)
        }
      }
      this.enableSelect = this.isselected.length > 0 || this.items.some((item) => item._selected)
    },
    selectAll() {
      this.enableSelect = true;
      this.items.forEach((item) => {
        this.$set(item, '_selected', true);
        this.$set(item, '_classes', item._selected ? "alert-primary" : "");
        const index = this.isselected.findIndex((selected) => selected.id === item.id)
        if (index <= -1) {
          this.isselected.push(item);
        }
      });
    },
    endSelect() {
      this.enableSelect = false
      this.items.forEach((item) => {
        this.$set(item, '_selected', false)
        this.$set(item, '_classes', "")
      });
      this.isselected = []
    },
    startSelect() {
      this.enableSelect = true
    },
    downloadAll() {
      const body_data = this.isselected
      const data = []
      body_data.forEach((item) => {
        if (item != undefined) {
          data.push({
            "domain": item.name,
            "cert_id": item.id,
            "shadows": item.shadows
          })
        }
      });
      axios({
        url: `cert/issue/allzip_download/`,
        method: 'POST',
        responseType: 'blob',
        data: {
          domain: data
        }
      }).then(response => {
        saveAs(response.data, `certificates.zip`)
      }).catch(error => {
        console.log(error)
      })
    },
    downloadCert(id, name) {
      axios({
        url: `cert/issue/${id}/zip/`,
        method: 'POST',
        responseType: 'blob'
      }).then(response => {
        saveAs(response.data, `${name}.zip`)
      }).catch(error => {
        console.log(error)
      })
    },
    searchData: _.debounce((vm) => {
      vm.fetchData()
    }, 1000),
    statusChanged() {
      this.page = 1
      this.fetchData()
    },
    fetchData() {
      this.listLoading = true
      let filterParams = {
        page: this.page,
        perpage: this.perpage
      }
      if (this.search) {
        filterParams['search'] = this.search
      }
      if (this.startDate && this.endDate) {
        let startDate = this.$moment(this.startDate).format('YYYY-MM-DD');
        let endDate = this.$moment(this.endDate).format('YYYY-MM-DD');
        
        filterParams['startDate'] = startDate;
        filterParams['endDate'] = endDate;
      }
      if (this.sortOrder) {
        filterParams['sortBy'] = this.sortOrder
        filterParams['sortOrder'] = this.sortField
      }

      let endpoint = ''
      switch (this.selectedStatus) {
        case this.$i18n.t('cert.Draft'):
          endpoint = 'cert/manage/draft/'
          break
        case this.$i18n.t('cert.ExpiringSoon'):
          endpoint = 'cert/manage/expiring_soon/'
          break
        case this.$i18n.t('cert.Expired'):
          endpoint = 'cert/manage/expired/'
          break
        case this.$i18n.t('cert.Issued'):
          endpoint = 'cert/manage/issued/'
          break
        case this.$i18n.t('cert.PendingValidation'):
          endpoint = 'cert/manage/pending_validation/'
          break
        case this.$i18n.t('cert.Error'):
          endpoint = 'cert/manage/error/'
          break
        default:
          endpoint = 'cert/manage/issued/'
      }

      return axios.get(endpoint, {
        params: filterParams,
      }).then(response => {
        this.items = response.data.results
        this.total_rows = response.data.count
        this.total_pages = Math.ceil(this.total_rows / this.perpage)
        this.listLoading = false
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.listLoading = false
      })
    },
    pageChanged(page) {
      this.page = page
      this.fetchData()
    },
    paginationChanged(itemsPerPage) {
      this.page = 1
      this.perpage = itemsPerPage
      this.fetchData()
    },
  }
}
</script>

<style>
#button-dropdown button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#search-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#search-wrapper .form-group {
  margin-right: 10px;
  margin-top: 1em;
}

#search-wrapper .form-group input {
  display: inline-block;
}
</style>

<style scoped>
.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.filter-group {
  margin-right: 20px;
}

.filter-label {
  margin-right: 10px;
}

.input-group {
  width: 300px;
}

.rounded-start {
  border-radius: 0.25rem;
}

#button-install {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid;
}

#button-dropdown {
  display: inline-block;
}
</style>
