<template>
  <CRow>
    <CCol>
        <CCard>
          <CCardHeader>
            {{ $t('cert.MyCertificate') }}
          </CCardHeader>
          <CCardBody>
            <CertTable/>
          </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import CertTable from './CertTable.vue'

export default {
  name: "CertIndex",
  components: {
    CertTable,
  }
}
</script>
